<template>
  <div class="game-container">
    <RainDropGame
      :optionsList="optionsList"
      :answerList="answerList"
      :bgImage="bgImage"
      @changeAnswerStatus="changeAnswerStatus"
      :starNum="3"
    />
  </div>
</template>

<script>
import RainDropGame from "@/components/Course/GamePage/RainDropGame";
export default {
  data() {
    return {
      bgImage: require("@/assets/img/16-GAMES/G53-drops/background.svg"),
      optionsList: [
        [
            {
            index: 1,
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-64/drop-tian.svg"),
            isAnswer: true,
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-64/drop-da.svg"),
          },
        
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-64/drop-ren.svg"),
          },
          
        ],
        [
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-64/drop-ba.svg"),
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-64/drop-fen.svg"),
            isAnswer: true,
            index: 2,
          },
          
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-64/drop-dao.svg"),
          },
        ],
        [
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-64/drop-ji.svg"),
          },
          
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-64/drop-er.svg"),
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-64/drop-yue.svg"),
            isAnswer: true,
            index: 3,
          },
        ],
      ],

      answerList: [
        [
          {
            hanzi: "今",
            pinying: "jīn",
            isChooseCorrect: true,
          },
          {
            id: 1,
            hanzi: "天",
            pinying: "tiān",
            isChooseCorrect: false,
          },
          
        ],
        [
          {
            hanzi: "9点30",
            pinying: "jiǔdiǎn sānshí",
            isChooseCorrect: true,
          },
          {
            id: 2,
            hanzi: "分",
            pinying: "fēn",
            isChooseCorrect: false,
          },
        
        ],
        [
          {
            hanzi: "3",
            pinying: " sān",
            isChooseCorrect: true,
          },
           {
            id: 3,
            hanzi: "月",
            pinying: "yuè",
            isChooseCorrect: false,
          },
          {
            hanzi: "5号",
            pinying: "wǔ hào",
            isChooseCorrect: true,
          },
         
        ],
      ],
    };
  },
  components: {
    RainDropGame,
  },
  methods: {
    changeAnswerStatus(item, index) {
      this.answerList[index].forEach((ele) => {
        if (ele.id === item.index) {
          ele.isChooseCorrect = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











