<template>
  <div class="game-container">
    <ClickImgToShowSentenceGame
      :bgImg="bgImg"
      :optionList="optionList"
      :maskImgList="maskImgList"
      @showCoeerctImg="showCoeerctImg"
      :lessonNO="64"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import ClickImgToShowSentenceGame from "@/components/Course/GamePage/ClickImgToShowSentenceGame";
export default {
  data() {
    return {
      titleInfo: {
        pinyin: "Xiànzài jǐ diǎn?",
        hanzi: "现在几点？",
      },
      bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-64/background.svg"),
      optionList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-64/alarm-clock.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-64/alarm-clock.svg"),
          id: 1,
          isShowCorrect: false,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-64/clock.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-64/clock.svg"),
          id: 2,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-64/computer.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-64/computer.svg"),
          id: 3,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-64/watch.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-64/watch.svg"),
          id: 4,
        },
      ],
      maskImgList: [
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-64/alarm-clock-story.svg"),
          id: 1,
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-64/clock-story.svg"),
          id: 2,
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-64/computer-story.svg"),
          id: 3,
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-64/watch-story.svg"),
          id: 4,
        },
      ],
    };
  },
  components: {
    ClickImgToShowSentenceGame,
  },
  methods: {
    showCoeerctImg(index) {
      this.optionList[index - 1].isShowCorrect = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
