<template>
  <div class="game-container">
    <RecognitionPinyinGame :optionList="optionList" :imgList="imgList" :isFullScreen="true" :isHaveAudio="true" :limitNumberOfVisibleOptions="3"/>
  </div>
</template>

<script>
import RecognitionPinyinGame from "@/components/Course/GamePage/RecognitionPinyinGame";
export default {
  data() {
    return {
      lessonId: 64,
      optionList: [
         {
          id: 1,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-08/background-yun.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-08/background-chi.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-08/background-ci.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-08/background-yu.svg"),
        },

      ],

      imgList: [

        {
          id: 1,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-08/frog-yun.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/yun.mp3"),
        },
        {
          id: 2,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-08/frog-chi.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/chi.mp3"),
        },
        {
          id: 3,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-08/frog-ci.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/ci.mp3"),
        },
        {
          id: 4,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-08/frog-yu.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/yu.mp3"),
        },
        {
          id: 5,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-08/frog-ri.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/ri.mp3"),
        },
        {
          id: 6,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-08/frog-ye.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/ye.mp3"),
        },
        {
          id: 7,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-08/frog-zhi.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/zhi.mp3"),
        },
      ],
    };
  },
  components: {
    RecognitionPinyinGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











