<template>
  <div class="game-container">
    <WriteHanziReviewPage
      :LeftImgList="LeftImgList"
      :hanziInfoList="hanziInfoList"
      :gifList="gifList"
    />
  </div>
</template>

<script>
import WriteHanziReviewPage from "@/components/Course/CoursePage/WriteHanziReviewPage";
export default {
  data() {
    return {
      hanziInfoList: [
        {
          pinyin: "rì",
          tipsList: ["今", "天", "是", "我", "的", "生", "日", "。"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-58/hanzi-ri-grey-border.svg"),
          stepNumber: 4,
          tipsIndex: 6,
        },
        {
          pinyin: "diǎn",
          tipsList: ["现", "在", "6", "点", "20", "分", "。"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-60/hanzi-dian-grey-border.svg"),
          stepNumber: 9,
          tipsIndex: 3,
        },
        {
          pinyin: "míng",
          tipsList: ["我", "明", "天", "去", "超", "市", "。"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-62/hanzi-ming-grey-border.svg"),
          stepNumber: 8,
          tipsIndex: 1,
        },
      ],
    };
  },
  computed: {
    LeftImgList() {
      const imgArr = [];
      for (let i = 1; i <= 3; i++) {
        imgArr.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-64/image-${i}.svg`)
        );
      }
      return imgArr;
    },
    gifList() {
      let imgArrMa = [];
      for (let i = 1; i <= 8; i++) {
        imgArrMa.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-62/lesson-62-ming-${i}.gif`)
        );
      }
      let imgArrYou = [];
      for (let i = 1; i <= 9; i++) {
        imgArrYou.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-60/lesson-60-dian-${i}.gif`)
        );
      }
      let imgArrRen = [];
      for (let i = 1; i <= 4; i++) {
        imgArrRen.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-58/lesson-58-ri-${i}.gif`)
        );
      }
      return [imgArrRen, imgArrYou, imgArrMa];
    },
  },
  components: {
    WriteHanziReviewPage,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
