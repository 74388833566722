<template>
  <div class="game-container">
    <FindElementByPicReviewGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      :totalStars="totalStars"
      :isNumberGame="false"
      :background="`blue`"
    />
  </div>
</template>

<script>
import FindElementByPicReviewGame from "@/components/Course/GamePage/FindElementByPicReviewGame";
export default {
  data() {
    return {
      totalStars: 6,
      questionInfoList: [{
          id: 1,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-57-hao.svg"),
          sound: require("@/assets/audio/L1/5-Words/hao4.mp3")
        },
        {
          id: 2,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-61-mingtian.svg"),
          sound: require("@/assets/audio/L1/5-Words/mingtian.mp3")
        },
         {
          id: 3,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-59-dian.svg"),
          sound: require("@/assets/audio/L1/5-Words/dian3.mp3")
        },
         {
          id: 4,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-59-fen.svg"),
          sound: require("@/assets/audio/L1/5-Words/fen1.mp3")
        },
        {
          id: 5,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-61-xingqi.svg"),
          sound: require("@/assets/audio/L1/5-Words/xingqi.mp3")
        },
        {
          id: 6,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-57-yue.svg"),
          sound: require("@/assets/audio/L1/5-Words/yue4.mp3")
        }
      ],
      asideElementInfo: {
        leftList: [
          {
            id: 1,
            element: "号",
            pinyin: "hào",
            sound: require("@/assets/audio/L1/5-Words/hao4.mp3")
          },
          {
            id: 2,
            element: "明天",
            pinyin: "míngtiān",
            sound: require("@/assets/audio/L1/5-Words/mingtian.mp3")
          },
          {
            id: 3,
            element: "点",
            pinyin: "diǎn",
            sound: require("@/assets/audio/L1/5-Words/dian3.mp3")
          }
        ],
        rightList: [
          {
            id: 4,
            element: "分",
            pinyin: "fēn",
            sound: require("@/assets/audio/L1/5-Words/fen1.mp3")
          },
          {
            id: 5,
            element: "星期",
            pinyin: "xīngqī",
            sound: require("@/assets/audio/L1/5-Words/xingqi.mp3")
          },
          {
            id: 6,
            element: "月",
            pinyin: "yuè",
            sound: require("@/assets/audio/L1/5-Words/yue4.mp3")
          }
        ]
      }
    };
  },
  components: {
    FindElementByPicReviewGame
  }
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
