<template>
  <div class="game-container">
    <FindElementByPicGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      :totalStars="totalStars"
      :isNumberGame="false"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import FindElementByPicGame from "@/components/Course/GamePage/FindElementByPicGame";
export default {
  data() {
    return {
      totalStars: 7,
      titleInfo: {
        pinyin: "Jīntiān xīngqī jǐ？",
        hanzi: "今天星期几？",
      },
      questionInfoList: [
        {
          id: 1,
          bgImg: require("@/assets/img/16-GAMES/G30-ten-buttons/lesson-64/image-1.svg"),
          sound: require("@/assets/audio/L1/5-Words/xingqiyi.mp3"),
        },

        {
          id: 2,
          bgImg: require("@/assets/img/16-GAMES/G30-ten-buttons/lesson-64/image-2.svg"),
          sound: require("@/assets/audio/L1/5-Words/xingqisan.mp3"),
        },
        {
          id: 3,
          bgImg: require("@/assets/img/16-GAMES/G30-ten-buttons/lesson-64/image-3.svg"),
          sound: require("@/assets/audio/L1/5-Words/xingqiliu.mp3"),
        },
        {
          id: 4,
          bgImg: require("@/assets/img/16-GAMES/G30-ten-buttons/lesson-64/image-4.svg"),
          sound: require("@/assets/audio/L1/5-Words/xingqier.mp3"),
        },
        {
          id: 5,
          bgImg: require("@/assets/img/16-GAMES/G30-ten-buttons/lesson-64/image-5.svg"),
          sound: require("@/assets/audio/L1/5-Words/xingqisi.mp3"),
        },
        {
          id: 6,
          bgImg: require("@/assets/img/16-GAMES/G30-ten-buttons/lesson-64/image-6.svg"),
          sound: require("@/assets/audio/L1/5-Words/xingqiwu.mp3"),
        },
        {
          id: 7,
          bgImg: require("@/assets/img/16-GAMES/G30-ten-buttons/lesson-64/image-7.svg"),
          sound: require("@/assets/audio/L1/5-Words/xingqitian.mp3"),
        },
      ],
      asideElementInfo: {
        leftList: [
          {
            id: 1,
            element: "星期一",
            pinyin: "Xīngqīyī",
          },
          {
            id: 4,
            element: "星期二",
            pinyin: "Xīngqī'èr",
          },
          {
            id: 2,
            element: "星期三",
            pinyin: "Xīngqīsān",
          },
        ],
        rightList: [
          {
            id: 5,
            element: "星期四",
            pinyin: "Xīngqīsì",
          },
          {
            id: 6,
            element: "星期五",
            pinyin: "Xīngqīwǔ",
          },
          {
            id: 3,
            element: "星期六",
            pinyin: "Xīngqīliù",
          },
          {
            id: 7,
            element: "星期天",
            pinyin: "Xīngqītiān",
          },
        ],
      },
    };
  },
  components: {
    FindElementByPicGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
