<template>
  <div class="game-container">
    <ReviewTrain
      :step="0"
      :vocabularyReviewList="vocabularyReviewList"
      :knowledgeReviewList="knowledgeReviewList"
      :pinyinWordReviewList="pinyinWordReviewList"
      :strokesReviewList="strokesReviewList"
    />
  </div>
</template>

<script>
import ReviewTrain from "@/components/Course/CoursePage/ReviewTrain";
export default {
  data() {
    return {
      vocabularyReviewList: [
        [
          {
            pinyin: "hào",
            hanzi: "号",
          },
          {
            pinyin: "jīntiān",
            hanzi: "今天",
          },
          {
            pinyin: "shēngrì",
            hanzi: "生日",
          },
          {
            pinyin: "xiànzài",
            hanzi: "现在",
          },
          {
            pinyin: "diǎn",
            hanzi: "点",
          },
          {
            pinyin: "fēn",
            hanzi: "分",
          },
         
        ],
        [
          {
            pinyin: "yuè",
            hanzi: "月",
          },
          {
            pinyin: "jiàn(miàn)",
            hanzi: "见（面）",
          },
          {
            pinyin: "xīngqī",
            hanzi: "星期",
          },
          {
            pinyin: "tāmen",
            hanzi: "他们",
          },
          {
            pinyin: "míngtiān",
            hanzi: "明天",
          },
          {
            pinyin: "dòngwùyuán",
            hanzi: "动物园",
          },
        ],
      ],
      knowledgeReviewList: [
        [
          {
            pinyin: "...yuè...hào",
            hanzi: "……月……号",
          },
          {
            pinyin: "Xiànzài jǐ diǎn?",
            hanzi: "现在几点？",
          },
          {
            pinyin: "Jīntiān xīngqī jǐ？",
            hanzi: "今天星期几？",
          },
         
        ],
      
      ],
      pinyinWordReviewList: [
        [
          {
            vocabulary: "zhi",
          },
          {
            vocabulary: "shi",
          },
          {
            vocabulary: "zi",
          },
          {
            vocabulary: "si",
          },
          {
            vocabulary: "wu",
          },
          {
            vocabulary: "ye",
          },
          {
            vocabulary: "yin",
          },
          {
            vocabulary: "yuan",
          },

        ],
        [
          {
            vocabulary: "chi",
          },
          {
            vocabulary: "ri",
          },
          {
            vocabulary: "ci",
          },
          {
            vocabulary: "yi",
          },
          {
            vocabulary: "yu",
          },
          {
            vocabulary: "yue",
          },
          {
            vocabulary: "yun",
          },
          {
            vocabulary: "ying",
          },

        ],
      ],
      strokesReviewList: [
        [
          {
            strokeImg: require("@/assets/img/18-Blue-review/lesson-58-shuwan-yellow.svg"),
          },
          {
            strokeImg: require("@/assets/img/18-Blue-review/lesson-60-hengzhewangou-yellow.svg"),
          },
          {
            strokeImg: require("@/assets/img/18-Blue-review/lesson-58-ri-white.svg"), 
          },


        ],
        [
          {
            strokeImg: require("@/assets/img/18-Blue-review/lesson-62-hengzhezhezhegou-yellow.svg"),
          },
          {
            strokeImg: require("@/assets/img/18-Blue-review/lesson-60-dian-white.svg"), 
          },
          {
            strokeImg: require("@/assets/img/18-Blue-review/lesson-62-ming-white.svg"),
          },
          
        ],
      ],
    };
  },
  components: {
    ReviewTrain,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>