<template>
  <div class="game-container">
    <SpeakingSentence  :gameList="gameList" :isClick="false" :hasMenu="false"/>
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentence";
export default {
  data() {
    return {
      gameList:[
        {
          bgImg:require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-64-2/image.svg`),
        },
      ],
    };
  },
  components: {
    SpeakingSentence,
  },
}
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>